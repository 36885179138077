body, html {
    margin: 0;
    padding: 0;
    font-family: 'Courier New', monospace;
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    position: relative;
    background-color: rgba(0,0,0,0.8);
}

#menuIcon, #navbar {
    position: fixed;
    z-index: 1001;
}

#menuIcon {
    top: 40px;
    left: 40px;
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    z-index: 1002;
    user-select: none;
}

#about {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 20%;
    left: 10%;
    width: 50vw;
    height: 50%;
    opacity: 0;
    animation: fadeIn 2s ease-in-out forwards;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

#about p span {
    background-color: black;
    color: white;
}
#navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    background: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    flex-direction: row;
    z-index: 1003;
}

#navbar.none {
    display: none;
}

#navbar ul {
    list-style: none;
    padding: 20px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#navbar li {
    margin: 0 10px;
}

#navbar a {
    text-decoration: none;
    color: white;
}

#nameEmblem {
    position: fixed;
    right: 17px;
    top: 10px;
    color: white;
    font-size: 70px;
    z-index: 1004;
}

#nameEmblem:after {
    content: '_';
    animation: blink 1s infinite step-start;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}

/* Adjustments for mobile layout */
@media (max-width: 800px) {
    #nameEmblem {
        position: fixed;
        text-align: center;
        bottom: 90px;
        top: auto;
        right: 20px;
        background-color: black;
        padding: 20px;
        border-radius: 20px;
        font-size: 20px;
    }

    #navbar {
        flex-direction: column;
        padding: 10px;

    }

    #navbar ul {
        padding: 10px;
    }
}

@media (max-width: 600px) {
    #nameEmblem {
        font-size: 14px;
    }
}

canvas {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

#aboutSection, #projectsGrid {
    margin-top: 125px;
    margin-bottom: 100px;
    max-width: 2000px;
    padding: 20px;
    /* background: white; */
    opacity: 0.2;
    animation: fadeIn 2s ease-in-out forwards;
}

#aboutSection {
    border: 2px solid rgb(0, 0, 0);
    width: 40vw;
    /* margin-top: 700px; */
    background-color: white;
    color: black;
    gap: 20px;
    padding: 20px;
    justify-content: center;
    align-items: center;
}

/* CSS */
#projectsGrid {
    width: 30vw;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;
    justify-content: end;
}

.projectItem {
    color: white;
    background-color: #000000;
    /* border: 1px solid #ccc; */
    padding: 20px;
}

@media screen and (max-width: 600px) {
    #projectsGrid {
        grid-template-columns: 1fr;
        justify-content: stretch;
    }
}

/* .projectItem::before {
    content: '';
    display: block;
    padding-top: 70%;
} */

.projectItemContent {
    /* position: absolute; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    margin: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.projectItemContent a {
    color: white;
    text-decoration: none;
    /* font-size: 20px; */
}

footer {
    position: fixed;
    left: 0;
    right: 0; 
    bottom: 0;
    width: 100%;
    background-color: #000000;
    color: white; 
    text-align: center;
    padding: 10px 0;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    z-index: 1001;
}

footer a {
    color: white;
    margin: 0 10px;
}

footer a:hover {
    color: #ddd;
}

.main-content {
    right: 70%;
    position: relative;
    /* z-index: 2; */
  }

@media (max-width: 800px) {
    .main-content {
        right: 0;
    }

    #projectsGrid, #about{
        width: 80vw;
    }

    #projectsGrid {
        margin-top: 100px;
        margin-bottom: 130px;
    }
}