.fade-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s, visibility 0.5s ease-in-out;
    z-index: 999;
  }
  
  .fade-overlay.show {
    opacity: 1;
    visibility: visible;
  }